<template>
  <div class="cources wrap px-md-8 px-3">
    <div class="d-flex flex-wrap align-center mt-6">
      <div class="h2 me-11">Courses</div>
      <div class="d-flex align-center mt-2 mx-3">
        <div class="text--text text-uppercase h9">Category:</div>
        <v-menu offset-y nudge-bottom="5">
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn plain v-bind="attrs" v-on="on" height="20">
              {{ categoriesValue.title.toUpperCase() }} <v-icon>{{ !value ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in categoriesList" class="menu-item h8" :key="item.id" @click="changeCategory(item)">
              {{ item.title.toUpperCase() }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div class="d-flex align-center mt-2 mx-3">
        <div class="text--text text-uppercase h9">Status:</div>
        <v-menu offset-y nudge-bottom="5">
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn plain v-bind="attrs" v-on="on" height="20">
              {{ statusValue.toUpperCase() }} <v-icon>{{ !value ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in statusList" class="menu-item h8" :key="item" @click="changeStatus(item)">
              {{ item.toUpperCase() }}
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <v-spacer></v-spacer>
      <v-btn to="/new-course" outlined width="125" height="34"><v-icon left>mdi-plus</v-icon> Add Courses</v-btn>
    </div>
    <div v-if="!data.results.length" class="empty-block d-flex align-center justify-center mt-5 mb-3">
      <v-card class="pt-15 pb-12 text-center" max-width="500" width="100%" min-height="400">
        <img src="@/assets/icon/empty-cources.svg" />
        <div class="h4 mt-6">There will be your courses list</div>
        <div class="text--text h10 mb-11">Click on the button to create your first course</div>
        <v-btn to="/new-course" width="120" height="34" color="secondary"><v-icon left>mdi-plus</v-icon> Add Course</v-btn>
      </v-card>
    </div>
    <div v-else class="d-flex flex-wrap mt-1">
      <v-card
        v-for="item in data.results"
        :key="item.id"
        :to="`/course/${item.id}`"
        width="200"
        min-height="220"
        class="cources-card mt-6 mx-2"
        :class="item.status == 'active' ? 'status-active' : 'status-inactive'"
      >
        <v-card tile flat min-height="155" class="pt-5 px-4 pb-2 cources-card_title">
          <div class="gray--text h9 text-uppercase">{{ item.category.title }}</div>
          <div class="mt-5 h4">{{ item.title }}</div>
          <div class="h10 text--text">{{ item.short_description }}</div>
        </v-card>
        <v-divider></v-divider>
        <v-card tile flat min-height="60" class="py-3 ps-4 pe-6 d-flex justify-space-between">
          <div class="h11">
            <div>Lessons</div>
            <div>{{ item.lessons.length }}</div>
          </div>
          <div><img class="mt-1" src="@/assets/icon/right.svg" alt="arrow-right" /></div>
        </v-card>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      page: +this.$route.query.page || 1,
      categoriesValue: { id: 0, title: 'all' },
      categoriesList: [],
      statusValue: this.$route.query.status || 'all',
      statusList: ['all', 'active', 'inactive'],
      error: [],
    };
  },
  mounted() {
    this.getData();
    this.$store.dispatch('getCategories');
  },
  methods: {
    async getData() {
      const data = new Object();
      data.status = this.statusValue;
      data.category = this.$route.query.category || this.categoriesValue.id;
      data.page = this.page;
      await this.$store.dispatch('getCoursesList', data);
    },
    async newCourse() {
      const data = new Object();
      data.title = this.data.title;
      data.short_description = this.data.short_description;
      data.description = this.data.description;
      data.category = this.data.category;
      data.level = this.data.level;
      data.lesson_duration_minutes = this.data.lesson_duration_minutes;
      await this.$store.dispatch('addCourses', data);
    },
    changeCategory(item) {
      this.categoriesValue = item;
      this.getData();
    },
    changeStatus(item) {
      this.statusValue = item;
      this.getData();
    },
  },
  watch: {
    categoriesData() {
      if (!this.categoriesList.length) {
        this.categoriesList = this.categoriesData;
        this.categoriesList.unshift({ id: 0, title: 'All' });
        this.categoriesValue = this.categoriesList.find((item) => item.id == this.$route.query.category) || { id: 0, title: 'all' };
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.coursesList;
    },
    categoriesData() {
      return this.$store.getters.categoriesList;
    },
  },
  destroyed() {
    this.$store.dispatch('setCoursesList', { results: [] });
    this.$store.dispatch('setCategories', []);
  },
};
</script>

<style lang="scss">
.menu-item {
  min-height: 30px !important;
}
.cources {
  .empty-block {
    min-height: calc(100vh - 200px);
  }
  .v-btn--outlined {
    background: #ffffff;
  }
  &-card {
    border-top: 4px solid;
  }
  .status-active {
    border-color: #46c64e !important;
  }
  .status-inactive {
    border-color: #b7bfcc !important;
  }
}
</style>
